import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/views/layouts/MainLayout.vue"),
    name: "Main",
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/pages/HomePage.vue"),
      },
      {
        path: "/packages/:category",
        name: "Packages",
        component: () => import("@/views/pages/PackagesPage.vue"),
      },
      {
        path: "/tour-details",
        name: "Tour Details",
        component: () => import("@/views/pages/TourDetails.vue"),
      },
      {
        path: "/blog-details",
        name: "Blog Details",
        component: () => import("@/views/pages/BlogDetails.vue"),
      },
      {
        path: "/destinations",
        name: "Destinations",
        component: () => import("@/views/pages/DestinationsPage.vue"),
      },
      {
        path: "/blogs",
        name: "Blogs",
        component: () => import("@/views/pages/BlogsPage.vue"),
      },
      {
        path: "/contact",
        name: "Contact Us",
        component: () => import("@/views/pages/ContactPage.vue"),
      },
      {
        path: "/:catchAll(.*)",
        name: "Not Found",
        component: () => import("@/views/layouts/NotFound.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  next();
});
export default router;
